@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {
    @media print {
      @page {
        size: A4 landscape; /* Define el tamaño A4 */
        margin: 10mm; /* Márgenes de 20mm */
      }
      .page-break {
       
        page-break-after: always;
        
    }
  
      /* Aplica estilo a los textos y componentes específicos durante la impresión */
      .print-container {
        max-width: 100%;
        padding: 0;
        margin: 0;
       
      }
  
      /* Ajusta el tamaño de fuente para la impresión */
      .text-xs {
        font-size: 10px;
      }
  
      /* Oculta botones y otros elementos interactivos */
      button {
        display: none;
      }
  
      /* Ajusta las celdas de las tablas */
      .break-words {
        word-break: break-word;
        white-space: normal;

      }
      
      .max-w-150px {
        max-width: 150px;
      }
  
      /* Opcional: Configura el ancho completo de la página */
      body {
        width: 100%;
        font-size: 12px; /* Tamaño de fuente global */
      }
    }
  }
  